import React, { Component } from 'react';

import Slider from "react-slick";
import axios from 'axios'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from "react-ga4";

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      feyrle_ip: 'https://feyrle-dot-heph2-338519.uc.r.appspot.com',
      feyrleRecs: null,
      busy: false,
      guessRows: [],
      solutionRec: null,
    };

    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.snackMsg = this.snackMsg.bind(this);
    this.handleErrorMsg = this.handleErrorMsg.bind(this);
    this.handleWarnMsg = this.handleWarnMsg.bind(this);
    this.handleInfoMsg = this.handleInfoMsg.bind(this);
    this.handleMakeGuess = this.handleMakeGuess.bind(this);
    this.getBackgroundColor = this.getBackgroundColor.bind(this);


  }

  componentDidMount() {
    this.refreshFeyrle();
    console.log('mounting');
  }

  refreshFeyrle() {
    console.log("getting feyrle");

    axios.get(this.state.feyrle_ip + '/feyrle/product/random', {
      headers: {
      },
      params: {
        number: 10,
      }
    })
      .then(response => {
        console.log("feyrle response", response);
        let guessRow = [];
        response.data.game_keys.map((key, idx) => {
          guessRow.push("initial");
        });


        let guessRows = [guessRow];
        this.setState({
          busy: false,
          feyrleRecs: response.data.recs,
          solutionRec: response.data.solution,
          guessRows: guessRows,
          solutionKeys: response.data.game_keys,
          solutionKeysNames: response.data.game_keys_names,
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => { this.setState({ avatar_busy: false }) });
  }

  handleInfoMsg(m) {
    this.snackMsg(m, "info");
  }
  handleWarnMsg(m) {
    this.snackMsg(m, "warning");
  }
  handleErrorMsg(m) {
    this.snackMsg(m, "error");
  }

  handleMakeGuess(idx) {
    console.log("guessing idx", this.state.feyrleRecs)
    let feyrleRec = this.state.feyrleRecs[idx];
    let guessRows = this.state.guessRows;
    let answers = [];
    this.state.solutionKeys.map((key, idx) => {
      if (feyrleRec[key] === this.state.solutionRec[key]) {
        answers.push("correct");
      } else {
        answers.push("incorrect");
      }
    });

    guessRows.splice(1, 0, answers).join();
    console.log(answers);
    console.log(guessRows);
    this.setState({ guessRows: guessRows });
  }
  snackMsg(m, s) {
    this.setState({
      snackSeverity: s,
      snackMessage: m,
      snackOpen: true
    });
  }
  handleCloseSnackbar(e) {
    console.log('snack bar is closed');
    this.setState({ snackOpen: false });
  }

  getBackgroundColor(forAnswer) {
    if (forAnswer === "correct")
      return { backgroundColor: "green" };
    else if (forAnswer === "incorrect")
      return { backgroundColor: "yellow" };
    else if (forAnswer === "initial")
      return { backgroundColor: "gray" };
    return {}
  }

  render() {

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    ReactGA.send({ hitType: "pageview", page: "/", title: "Feyrle Top" });
    return (
      <Box sx={{ flexGrow: 1 }} >
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" align="center" justify="center" >
          <Grid item xs={4} sm={8} md={12}  >
            <Box sx={{ maxWidth: 600, flexGrow: 1 }}>
              <Card sx={{ maxWidth: 600, maxHeight: 800, overflow: "auto" }} style={{ backgroundColor: 'transparent', width: 600 }}>
                <CardContent>
                  <div>
                    {this.state.feyrleRecs ?

                      <Box sx={{ maxWidth: 600, flexGrow: 1 }}>
                        <Slider {...settings}>

                          {this.state.feyrleRecs.map((avatar, index) => (
                            <CardMedia
                              component="img"
                              image={'https://' + avatar.thumb_path}
                              onClick={e => { this.handleMakeGuess(index) }}
                            />
                          ))}

                        </Slider>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 550, maxWidth: 550 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {this.state.solutionKeysNames.map((header) => (
                                  <TableCell
                                    key={header}
                                    align="right">{header}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.guessRows.map((row, index) => (
                                <TableRow
                                  key={"row" + index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  {this.state.solutionKeys.map((key, solution_idx) => (
                                    <TableCell component="th" scope="row"
                                      sx={this.getBackgroundColor(row[solution_idx])}>
                                      {this.state.solutionRec[key]}
                                    </TableCell>

                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      : null}
                  </div>
                </CardContent>
              </Card>

            </Box>
          </Grid>
        </Grid>
      </Box >


    )
  }
}


export default App